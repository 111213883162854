import { createStyles } from "@mantine/core";

export const useStyles = createStyles(
	(theme, { withBorder }: { withBorder?: boolean }) => ({
		image: {
			border: withBorder
				? `1px solid ${
						theme.colorScheme === "dark"
							? theme.colors.dark[4]
							: theme.colors.gray[3]
				  }`
				: "none",
		},

		figure: {
			height: "inherit",
		},

		imageWrapper: {
			height: "inherit",
		},

		zoomedImageRoot: {
			height: "inherit",
		},

		zoomedImageFigure: {
			height: "inherit",
		},

		zoomedImageWrapper: {
			height: "inherit",
		},

		enlargeContainer: {
			position: "fixed",
			backgroundColor: "white",
			left: 12,
			top: 12,
			width: "calc(100vw - 24px)",
			height: "calc(100vh - 24px)",
			zIndex: 9999,
		},
	}),
);
