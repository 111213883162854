import type { ImageProps } from "@mantine/core";
import { Image } from "@mantine/core";
import { forwardRef, useState } from "react";
import { useStyles } from "./enlargable-image.styles";

type EnlargeableImageProps = ImageProps & { withBorder?: boolean };

export const EnlargeableImage = forwardRef<
	HTMLImageElement,
	EnlargeableImageProps
>((props, ref) => {
	const { classes } = useStyles({ withBorder: props.withBorder });
	const [shouldZoom, setShouldZoom] = useState(false);

	return (
		<>
			<Image
				{...props}
				sx={{ ...props.sx, cursor: "zoom-in" }}
				onClick={() => setShouldZoom(true)}
				imageRef={ref}
				classNames={{
					image: classes.image,
					figure: classes.figure,
					imageWrapper: classes.imageWrapper,
					...props.classNames,
				}}
			/>
			{shouldZoom && (
				<div
					role="button"
					className={classes.enlargeContainer}
					onClick={() => setShouldZoom(false)}
				>
					<Image
						src={props.src}
						withPlaceholder
						height="100%"
						sx={{ cursor: "zoom-out" }}
						classNames={{
							root: classes.zoomedImageRoot,
							figure: classes.zoomedImageFigure,
							imageWrapper: classes.zoomedImageWrapper,
						}}
					/>
				</div>
			)}
		</>
	);
});
